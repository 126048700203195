import React from 'react';
import logo from '../images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  return (
    <section className="contact-section">
      {/* Left section with logo and text */}
      <div className="left-section">
        <img src={logo} alt="Logo" className="logo" />
        <p>Παραμένουμε στη διάθεσή σας για οποιαδήποτε διευκρίνηση ή πληροφορία . Μη διστάσετε να επικοινωνήσετε μαζι μας.</p>
      </div>

      {/* Center section with phone numbers */}
      <div className="center-section">
        <div className="phone">
          <FontAwesomeIcon icon={faPhone} />
          <span>Ασφαλιστικές Υπηρεσίες: <br /> +30 693 229 4297</span>
        </div>
        <div className="phone">
          <FontAwesomeIcon icon={faPhone} />
          <span>Υπηρεσίες Ψυχοθεραπείας: <br /> +30 694 952 4673</span>
        </div>
      </div>

      {/* Right section with email address */}
      <div className="right-section">
        <div className="email">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>petrakihel@gmail.com</span>
        </div>
      </div>
    </section>
  );
};

export default Contact;