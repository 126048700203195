import React from 'react';
import Title from '../components/Title';
import { FaHeart, FaUser, FaIndustry } from 'react-icons/fa';

const Services = () => {
  const services = [
    {
      icon: <FaUser />,
      title: 'Ασφάλειες για Ιδιώτες',
      info: 'Ολοκληρωμένες υπηρεσίες ασφάλισης για τον ιδιώτη, για την αποφυγή κινδύνων που κρύβόνται στην καθημερινότητά μας.',
    },
    {
      icon: <FaIndustry />,
      title: 'Ασφάλειες για Επαγγελματίες',
      info: 'Επιλέξτε την κατάλληλη ασφάλιση για την επιχείρησή σας.',
    },
    {
      icon: <FaHeart />,
      title: 'Συνεδρίες ψυχοθεραπείας',
      info: 'Αναζητώντας λύσεις στα ψυχολογικά αδιέξοδα, διευκολύνουμε & απολαμβάνουμε τη ζωή μας! ',
    },
  ];

  return (
    <section className='services'>
      <Title title='Services' />
      <div className='services-center'>
        {services.map((item, index) => (
          <article key={index} className='service'>
            <span>{item.icon}</span>
            <h6>{item.title}</h6>
            <p>{item.info}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Services;