import React from 'react';

const Header = () => {
  return (
    <header className='header'>
      <div className='header-text'>
        <h1 class="home-header1">Care Assurance Group</h1>
        <h3 class="home-header2">Η δική σου Ασφαλιστική Κάλυψη</h3>
      </div>
    </header>
  );
};

export default Header;