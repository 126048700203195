import React from 'react';
import Accordion from '../components/Accordion';
import Header from '../components/Header';
import Title from '../components/Title';
import Services from '../components/Services';
import Contact from '../components/Contact';
import insuranceImage from '../images/insurance.jpg' 
import psychologyImage from '../images/Psychology.jpg'

const Home = () => {

  const accordionData1 = [
    { header: 'ΥΓΕΙΑΣ', content: 'Για να κάνουμε τα ονειρά μας πραγματικότητα προϋπόθεση είναι η διατήρηση της καλής υγείας, το σημαντικότερο αγαθό. Check up δωρεάν, ιδιώτες γιατρούς και αερομεταφορά.' },
    { header: 'ΖΩΗΣ', content: 'Για την διασφάλιση της οικογένειας μας και κάλυψη από διάφορους κινδύνους' },
    { header: 'ΠΕΡΙΟΥΣΙΑΣ', content: 'Διασφάλιση της κατοικίας ή της επιχείρησης μας από τους δίαφορους κινδύνους με δική μας πρωτοβουλία.' },
    { header: 'ΟΧΗΜΑΤΑ - ΣΚΑΦΗ', content: '  ' },
    { header: 'ΣΥΝΤΑΞΗ', content: 'Εξασφαλίζοντας ένα εισόδημα για σιγουριά στο μέλλον, είτε ως συμπληρωματική σύνταξη ή για αποκατάσταση των παιδιών μας.' },
    { header: 'ΤΑΞΙΔΙΩΤΙΚΕΣ ΑΣΦΑΛΙΣΕΙΣ', content: 'Ολοκληρωμένα προγράμματα ασφάλισης ταξιδιών αναψυχής.' },
    { header: 'ΠΡΟΣΩΠΙΚΩΝ ΑΤΥΧΗΜΑΤΩΝ', content: 'Κάλυψη από αποιοδήποτε μεταφορικό μέσο.' },
    { header: 'ΕΞΕΙΔΙΚΕΥΜΕΝΕΣ ΛΥΣΕΙΣ', content: 'Τεχνικές ασφαλίσεις κατά παντός κινδύνου ανεγερσης οικοδομών, φωτοβολταϊκών, αιολικών πάρκων, μηχανημάτων έργου κλπ.' },
    { header: 'ΑΣΦΑΛΙΣΕΙΣ ΜΕΤΑΦΕΡΟΜΕΝΩΝ ΕΜΠΟΡΕΥΜΑΤΩΝ', content: '  ' },
    { header: 'ΕΠΑΓΓΕΛΜΑΤΙΚΗ ΑΣΤΙΚΗ ΕΥΘΥΝΗ', content: '  ' },
    { header: 'ΑΠΟΤΑΜΙΕΥΣΗ - ΕΠΕΝΔΥΣΕΙΣ', content: '  ' },
  ];

  const accordionData2 = [
    { header: 'Ατομικές συνεδρίες ενηλίκων', content: 'Σκοπός της ψυχοθεραπείας πολλές φορές αποτελεί η αναζήτηση και η ενδυνάμωση του εαυτού, η αντιμετώπιση των καθημερινών προκλήσεων και δυσκολιών αλλά και η ενίσχυση της ψυχικής υγείας και ευεξίας μέσα από την δημιουργία ενός ασφαλούς και σταθερού πλαισίου, εκείνου της ψυχοθεραπείας. Ο ψυχοθεραπευτής θεωρείται συνοδοιπόρος σε αυτό το ταξίδι που ξεδιπλώνεται, με προορισμό την εξέλιξη και την αλλαγή του θεραπευόμενου μέσα από την ανεύρεση των βαθύτερων νοημάτων και ερμηνειών.' },
    { header: 'Ατομικές συνεδρίες εφήβων', content: 'Κατά την εφηβική ηλικία παρατηρείται ένα συνονθύλευμα από αναπτυξιακές αλλαγές που συντελούνται στον ανθρώπινο οργανισμό, όπως ορμονικές, σωματικές και ψυχολογικές, καθιστώντας την εφηβική περίοδο ενός ανθρώπου ίσως την πιο ταραχώδη σε ολόκληρη τη ζωή. Ο έφηβος έρχεται αντιμέτωπος με πρωτόγνωρες καταστάσεις για τον ίδιο, όπως είναι η ανακάλυψη της ταυτότητάς του, η έντονη συναισθηματική φύση του, η σημαντική εμπλοκή με συνομηλίκους και το έντονο άγχος για τις σχολικές επιδόσεις. Οι παραπάνω καταστάσεις αποτελούν σημαντικές αιτίες για την αναζήτηση βοήθειας από έναν ειδικό.' },
    { header: 'Συμβουλευτική γονέων', content: 'Η συμβουλευτική γονέων αποτελεί τη συνεργασία μεταξύ των γονέων και ενός ψυχοθεραπευτή/ παιδοψυχολόγου προκειμένου να προσφερθεί βοήθεια όσον αφορά στην αναγνώριση, αποδοχή και αντιμετώπιση των δυσκολιών σχετικά με τη φύση των προβλημάτων που απασχολούν το παιδί τους. Δίνεται έμφαση στην διαπαιδαγώγηση, στην εγκαθίδρυση νέων κανόνων βοηθητικών για το παιδί καθώς επίσης και στην επίλυση προβλημάτων όσο αφορά τη σχέση μεταξύ γονέα και παιδιού.' },
    { header: 'Οικογενειακή θεραπεία', content: 'Η οικογενειακή θεραπεία έχει ως σημαντικό χαρακτηριστικό την εμπλοκή όλων των μελών της οικογένειας, καθώς γίνεται προσπάθεια να ακουστούν ανοιχτά και με σεβασμό τα προβλήματα και οι θέσεις όλων όσων εμπεριέχονται σε αυτή. Σκοπός λοιπόν αποτελεί η εκμάθηση λειτουργικών τρόπων επικοινωνίας και η γνωριμία με νέες συνθήκες και συμπεριφορές που θα μπορέσουν να οδηγήσουν την οικογένεια σε νέα εξελικτικά μονοπάτια όταν εκείνη νιώθει ότι έχει φτάσει σε τέλμα.' },
    { header: 'Θεραπεία ζεύγους', content: 'Οι ερωτικές σχέσεις όπως και κάθε άλλη μορφή σχέσης δύο ανθρώπων διακατέχεται από πολλές δυσκολίες που μπορούν να οδηγηθούν στην κρίση. Το γεγονός αυτό μπορεί να κατανοηθεί λαμβάνοντας υπόψη την αφετηρία ζωής του κάθε ανθρώπου, τα διαφορετικά βιώματα, την εξέλιξη της προσωπικότητας του καθενός καθώς επίσης και τον επαναπροσδιορισμό των αναγκών και των επιθυμιών μέσα σε μια σχέση. Όλα τα παραπάνω μπορούν να συμβάλλουν στην δημιουργία ενός χάσματος που μπορεί να γεφυρωθεί έπειτα από αμοιβαία προσπάθεια και από τις δύο πλευρές, υπομονή και επιμονή καθώς επίσης και με σωστή καθοδήγηση και επικοινωνία.' },
  ];

  return (
    <div>
      <Header />
      <section id="services">
        <Services />
      </section>
      <section id="insurance">
        <Title title='Ασφαλιστικές Υπηρεσίες' />
        <div className="sectionContainer">
          <img src={insuranceImage} alt='insuranceImage' with/>
          <Accordion accordionData={accordionData1} />
        </div>
      </section>
      <section id="psychology">
        <Title title='Υπηρεσίες ψυχοθεραπείας και συμβουλευτικής' />
        <div className="sectionContainer">
          <Accordion accordionData={accordionData2} />
          <img src={psychologyImage} alt='psychologyImage' />
        </div>
      </section>
      <section id="Contact">
        <Contact />
      </section>

    </div>
  );
};

export default Home;