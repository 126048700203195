import React from 'react';

const Title = ({ title }) => (
  <div className='section-title'>
    <h3>{title}</h3>
    <div />
  </div>
);

export default Title;
