import React, { useState } from 'react';

const Accordion = ({ accordionData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='accordion-container'>
      {accordionData.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleAccordionClick(index)}
          >
            {item.header}
          </div>
          {activeIndex === index && (
            <div className="accordion-content active">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;