// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from '../images/logo.png';
import { FaAlignRight } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="home" smooth duration={700}>
            <img src={logo} alt="logo" />
          </Link>
          <button type="button" className="nav-btn" onClick={handleToggle}>
            <FaAlignRight className="nav-icon" />
          </button>
        </div>
        <ul className={isOpen ? 'nav-links show-nav' : 'nav-links'}>
          <li>
            <Link to="header" smooth duration={700}>Αρχική</Link>
          </li>
          <li>
            <Link to="insurance" smooth duration={700}>Ασφαλιστικές Υπηρεσίες</Link>
          </li>
          <li>
            <Link to="psychology" smooth duration={700}>Ψυχοθεραπεία</Link>
          </li>
          <li>
            <Link to="Contact" smooth duration={700}>Επικοινωνία</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
